:export {
  menu-color: #000;
  menu-color-active: #c41230;
  menu-background: #fff;
}

.org-list .org-list-tip {
  color: #c78a29;
  font-size: 2.14rem;
  font-weight: 600;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.org-list .org-list-item-container {
  border-radius: 8px;
  padding: 10px;
}
.org-list .org-list-item-container.org-one {
  max-width: 400px;
}
.org-list .org-list-item-container .org-list-item {
  background: #fff;
  border: 1px solid #c78a29;
  border-radius: 5px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-img img {
  width: 100%;
  border-radius: 5px 5px 0px 0px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-title {
  font-size: 1.07rem;
  padding: 12px 0px;
}
.org-list .org-list-item-container .org-list-item .org-list-item-title.ch {
  font-size: 1.07rem;
}

.org-type-menu {
  margin: 20px 0px;
  padding: 20px 20px 0px 20px;
  background: #ffffff;
  box-shadow: 0px 1px 17px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-flow: wrap;
}
.org-type-menu .org-type-item-container {
  margin-bottom: 20px;
  border-radius: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  border-right: 2px solid #d1d1d6;
}
.org-type-menu .org-type-item-container:last-child {
  border-right: transparent;
}
.org-type-menu .org-type-item-container .org-type-menu-item {
  font-size: 1.57rem;
  font-weight: 600;
  cursor: pointer;
}
.org-type-menu .org-type-item-container .org-type-menu-item.active, .org-type-menu .org-type-item-container .org-type-menu-item:hover, .org-type-menu .org-type-item-container .org-type-menu-item:focus {
  color: #c41230;
}